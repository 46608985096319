import util from '@bmp-vue/core/api/util'

const controller = 'ArquivoRecebidoPontoVenda'
export default Object.assign({}, util.GetCrud(controller, ['getall', 'get', 'put', 'delete']), {
    GetSituacaoPVs: function (dto) {
        return util.Axios.GetAll(controller + '/GetSituacaoPVs', dto)
    },
    GetAllNuLiquids: function (dto) {
        return util.Axios.GetAll(controller + '/NuLiquids', dto)
    }
})

