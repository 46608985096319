import util from '@bmp-vue/core/api/util'

const controller = 'TransferenciaRamificacao'
export default Object.assign({}, util.GetCrud(controller, ['getall']), {
  ExecutarAntecipacaoRamificacao(dto) {
    return util.Axios.Post(controller + '/Antecipar', dto)
  },
  GetQuantidadeAntecipacoes(dto) {
    return util.Axios.GetAll(controller + '/Antecipar/Quantidade', dto)
  },
  GetQuantidadeRamificada(dto) {
    return util.Axios.GetAll(controller + '/Ramificada/Quantidade', dto)
  },
  GetQuantidadeARamificar(dto) {
    return util.Axios.GetAll(controller + '/Ramificar/Quantidade', dto)
  },
  GetQuantidadeARamificarAnterior(dto) {
    return util.Axios.GetAll(controller + '/Ramificar/Anterior/Quantidade', dto)
  },
  GetAllRamificada(dto) {
    return util.Axios.GetAll(`${controller}/Ramificada/Grid`, dto)
  },
  GetAllRamificadaDetalhe(dto) {
    return util.Axios.GetAll(`${controller}/Ramificada/Grid/Detalhe`, dto)
  },
  GetAllRamificadaExcelGeral(dto) {
    return util.Axios.GetAll(`${controller}/Ramificada/Excel/Geral`, dto)
  },
  GetAllRamificar(dto) {
    return util.Axios.GetAll(`${controller}/Ramificar/Grid`, dto)
  },
  GetAllRamificarDetalhe(dto) {
    return util.Axios.GetAll(`${controller}/Ramificar/Grid/Detalhe`, dto)
  },
  GetAllRamificarExcelGeral(dto) {
    return util.Axios.GetAll(`${controller}/Ramificar/Excel/Geral`, dto)
  },
  GetAllAtrasada(dto) {
    return util.Axios.GetAll(`${controller}/Atrasada/Grid`, dto)
  },
  GetAllAtrasadaDetalhe(dto) {
    return util.Axios.GetAll(`${controller}/Atrasada/Grid/Detalhe`, dto)
  },
  GetAllAtrasadaExcelGeral(dto) {
    return util.Axios.GetAll(`${controller}/Atrasada/Excel/Geral`, dto)
  }
})
